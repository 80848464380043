import { token } from '../../token';
import { IIconProps } from '../type';

export const CalendarIconOutline = ({
    width = 20,
    height = 20,
    color = token.get<string>('global.color.grey-2'),
}: IIconProps): JSX.Element => (
    <svg width={width} height={height} viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <defs>
            <path id="sbw76ao1ea" d="M0 0h16v16H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
            <path
                d="M5.804 9.931a.802.802 0 0 0-.801.8.802.802 0 0 0 1.603 0c0-.44-.36-.8-.802-.8m0 2.562c-.972 0-1.763-.79-1.763-1.761a1.764 1.764 0 0 1 3.527 0c0 .97-.792 1.76-1.764 1.76"
                fill={color}
            />
            <g transform="translate(2 2)">
                <mask id="gz09wwfy9b" fill="#fff">
                    <use xlinkHref="#sbw76ao1ea" />
                </mask>
                <path
                    d="M13.916 15.04H2.084a1.123 1.123 0 0 1-1.122-1.121V6.015h14.076v7.904c0 .618-.503 1.12-1.122 1.12M2.084 2.082h1.72v.64a.48.48 0 0 0 .962 0v-.64h6.441v.64a.48.48 0 0 0 .962 0v-.64h1.747c.619 0 1.122.503 1.122 1.12v1.854H.962V3.2c0-.617.503-1.12 1.122-1.12m11.832-.96h-1.747V.48a.48.48 0 0 0-.962 0v.64h-6.44V.48a.48.48 0 0 0-.962 0v.64h-1.72C.934 1.12 0 2.053 0 3.2V13.92C0 15.066.935 16 2.084 16h11.832c1.15 0 2.084-.934 2.084-2.081V3.2a2.085 2.085 0 0 0-2.084-2.08"
                    fill={color}
                    mask="url(#gz09wwfy9b)"
                />
            </g>
            <path
                d="M8.395 10.732a.48.48 0 0 1 .48-.48h2.245a.48.48 0 1 1 0 .96H8.876a.48.48 0 0 1-.481-.48M12.588 10.732a.48.48 0 0 1 .48-.48h2.245a.48.48 0 1 1 0 .96H13.07a.48.48 0 0 1-.481-.48M8.395 14.408a.48.48 0 0 1 .48-.48h2.245a.48.48 0 1 1 0 .96H8.876a.48.48 0 0 1-.481-.48M4.201 14.408a.48.48 0 0 1 .481-.48h2.245a.48.48 0 1 1 0 .96H4.682a.48.48 0 0 1-.48-.48M12.588 14.408a.48.48 0 0 1 .48-.48h2.245a.48.48 0 1 1 0 .96H13.07a.48.48 0 0 1-.481-.48"
                fill={color}
            />
        </g>
    </svg>
);

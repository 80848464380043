import { token } from '../../token';
import { IIconProps } from '../type';

export const InfoIconFilled = ({
    width = 16,
    height = 16,
    color = token.get<string>('global.color.grey-2'),
}: IIconProps): JSX.Element => (
    <svg width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <g fill={color} fillRule="evenodd">
            <path d="M8 3.037c-.706 0-1.28.574-1.28 1.28 0 .706.574 1.28 1.28 1.28.706 0 1.28-.574 1.28-1.28 0-.706-.574-1.28-1.28-1.28zm-.096 3.166c-.653 0-1.184.53-1.184 1.184v4.734c0 .653.53 1.184 1.184 1.184h.192c.653 0 1.184-.531 1.184-1.184V7.387c0-.653-.531-1.184-1.184-1.184h-.192zM8 15.52C3.854 15.52.48 12.147.48 8 .48 3.854 3.854.48 8 .48c4.147 0 7.52 3.374 7.52 7.52 0 4.147-3.373 7.52-7.52 7.52z" />
            <path d="M7.904 12.825a.704.704 0 0 1-.704-.704V7.387c0-.39.315-.704.704-.704h.192c.389 0 .704.315.704.704v4.734a.704.704 0 0 1-.704.704h-.192zM8 5.117a.8.8 0 1 1 0-1.6.8.8 0 0 1 0 1.6zM8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm-.806 5.882a1.666 1.666 0 0 0-.954 1.505v4.734c0 .917.747 1.664 1.664 1.664h.192c.917 0 1.664-.747 1.664-1.664V7.387c0-.664-.39-1.239-.954-1.505A1.762 1.762 0 0 0 8 2.558c-.97 0-1.76.789-1.76 1.76 0 .68.388 1.27.954 1.564zM8 .96A7.048 7.048 0 0 1 15.04 8 7.048 7.048 0 0 1 8 15.04 7.048 7.048 0 0 1 .96 8 7.048 7.048 0 0 1 8 .96z" />
        </g>
    </svg>
);

import { token } from '../../token';
import { IIconProps } from '../type';

export const ReloadIconOutline = ({
    width = 16,
    height = 16,
    color = token.get<string>('global.color.grey-2'),
}: IIconProps): JSX.Element => (
    <svg width={width} height={height} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m13.022 6.683 1.402 1.693a.4.4 0 0 1-.616.51l-.777-.937c-.065 1.834-.853 3.4-2.297 4.302-1.9 1.188-4.448.936-6.205-.614a.5.5 0 0 1 .662-.75c1.43 1.262 3.493 1.466 5.014.516 1.174-.734 1.812-2.046 1.83-3.63l-1.023.867a.4.4 0 0 1-.505.01l-.058-.057a.4.4 0 0 1 .046-.564l1.677-1.42a.601.601 0 0 1 .85.074zm-1.51-2.63a.5.5 0 0 1-.662.749C9.42 3.54 7.356 3.336 5.836 4.286c-1.174.734-1.812 2.046-1.83 3.63l1.022-.866a.4.4 0 0 1 .506-.01l.058.056a.4.4 0 0 1-.046.564L3.869 9.08a.601.601 0 0 1-.85-.074L1.617 7.314a.4.4 0 0 1 .615-.51l.778.937c.064-1.834.853-3.4 2.296-4.303 1.901-1.188 4.449-.936 6.206.614z"
            fill={color}
            fillRule="nonzero"
        />
    </svg>
);
